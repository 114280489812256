<template>
  <div>
    <p style="display: flex;justify-content: space-between;margin-left: 25px;">
      <span style="font-size: 20px;font-weight: 300;">学生列表 ({{CountNum}})</span>
      <el-button type="primary" @click="$router.go(-1)" style="margin-right: 50px;">返回</el-button>
    </p>
    <el-table :data="studentData" style="width: 100%" v-loading="loading">
      <el-table-column prop="StuUserID" label="学生ID" align="center" width="100"></el-table-column>
      <el-table-column prop="StuNumber" label="学生编号" align="center"></el-table-column>
      <el-table-column prop="StuName" label="学生姓名" align="center"></el-table-column>
      <el-table-column prop="StuGender" label="学生性别" align="center"></el-table-column>
      <el-table-column prop="BirthdayDate" label="学生生日" align="center"></el-table-column>
      <el-table-column prop="Status" label="学生状态" align="center" width="150"></el-table-column>
    </el-table>
    <div>
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top: 10px">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    getStudentsList
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        levelId: this.$route.params.levelId, //年级id
        schoolId: this.$route.params.schoolId, //学校id
        classId: this.$route.params.classId, //班级id
        studentData: [], // 学生列表
        currpage: 1, // 初始页
        pagesize: 14, // 每页显示条目个数
        CountNum: 0, // 总条数
        loading: false, //加载框
      }
    },
    mounted() {
      this.getStudentsList(1) // 获取学生列表
	  
	   
    },
    methods: {
      getStudentsList(currpage) { // 班级列表
        this.loading = true
        const data = {
          SchoolID: this.schoolId,
          ClassType: !!this.levelId ? this.levelId : "",
          ClassID: !!this.classId ? this.classId : "",
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        getStudentsList(data).then(res => {
          console.log(res.data.List)
          this.studentData = res.data.List // 班级列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.getStudentsList(val)
        this.currpage = val
      }
    }
  }
</script>
